import React from "react"
import { Button } from "@material-ui/core"
import { useButtonStyles } from "../styles/styles"
import { Link } from "gatsby"

export const Card = ({
  title = "title",
  imageUrl = "https://i.imgur.com/ymei7Jd.jpg",
  buttonText,
  children,
  link,
  style = {}
}) => {
  const styles = useButtonStyles()

  return (
    <div className="card" style={style}>
      <div
        className="card__image-shadow"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div
        className="card__image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
      <div className="card__content">
        <div className="card__title">{title}</div>
        <div className="card__body">{children}</div>
        {buttonText && (
          <Link to={link}>
            <Button
              variant="contained"
              style={{ marginTop: 20 }}
              className={styles.primaryButton}
            >
              {buttonText}
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}
