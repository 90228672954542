import React, { useEffect } from "react"
import { Container, Typography, Button, Grid } from "@material-ui/core"
import { Link } from "gatsby"

import Navbar from "../components/sections/Navbar"
import Swiper from "../components/Swiper"

import ContactUsSection from "../components/sections/ContactUsSection"
import { Card } from "../components/card"
import Calandar from "../components/calendar"
import { useButtonStyles } from "../styles/styles"
import { PAGE_URLS, SERVICE_URLS } from "../enum/PageUrls"
import Img from "gatsby-image"

import "../sass/index.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { detectBrowser } from "../hooks/detectBrowser"

const GatsbyShadowImage = ({ gatsbyImage, title, altText }) => (
  <div className="gatsby-shadow-image">
    <Img fluid={gatsbyImage.childImageSharp.fluid} />
    <img
      className="gatsby-shadow-image__shadow"
      src={gatsbyImage.childImageSharp.resize.src}
      title={title}
      alt={altText}
    />
  </div>
)

const services = [
  {
    title: "Wedding Receptions",
    body:
      "Whether you’re celebrating with just family, the entire neighborhood or a large group up to 350 people we can find a place for you.",
    imageUrl: "https://i.imgur.com/6Zag0YJ.jpg",
    link: SERVICE_URLS.WEDDING_RECEPTIONS
  },
  {
    title: "Corporate Functions",
    body:
      "Retirement parties, Christmas gatherings, seminars and business meeting, depending on the size of the event can be hosted in the Main or Lower Hall.",
    imageUrl: "https://i.imgur.com/KmMxGzq.jpg",
    link: SERVICE_URLS.CORPORATE_FUNCTIONS
  },
  {
    title: "Happier Holidays",
    body:
      "Spend time with your guests and enlist our help for your next family holiday.",
    imageUrl: "https://i.imgur.com/OTmegVy.png",
    link: SERVICE_URLS.HAPPIER_HOLIDAYS
  },
  {
    title: "Birthday Parties",
    body:
      "Have your birthday party at the KC Hall! We offer affordable drink packages and a cozy/fun space to celebrate that special day!",
    imageUrl: "https://i.imgur.com/WkY8aFx.jpg",
    link: SERVICE_URLS.BIRTHDAY_PARTIES
  },
  {
    title: "Funeral Services and Catering for Sensitive Occasions",
    body:
      "We can assist you in hosting a memorial service, military event, charity function or fund raiser.",
    imageUrl: "https://i.imgur.com/WsJ5ujE.jpg",
    link: SERVICE_URLS.FUNERAL_SERVICES
  },
  {
    title: "Other",
    body:
      "Whether your raising money for your school's sports teams, a charitable cause, or for any other worthy reason, the KC Hall is here to help at an affordable rate.",
    imageUrl: "https://i.imgur.com/tsnZQ55.jpg",
    link: SERVICE_URLS.FUNDRAISERS
  },
]

const HomePage2 = ({ data }) => {
  const classes = useButtonStyles()

  const scrollToWeeklyEvents = () => {
    document.getElementById("weekly-events").scrollIntoView()
    window.scrollBy(0, -80)
  }

  const scrollToContactForm = () => {
    document.getElementById("contact-us").scrollIntoView()
  }

  useEffect(() => {
    detectBrowser()
  }, [])


  const {
    kchalldark,
    kchallsign2,
    kchallplates,
    kchalloutside,
    barell,
    downstairsdark,
    downstairslight,
    drinks,
    weddingsetupclose,
    weddingsetupfar,
  } = data || {}

  return (
    <Layout>
      <div className="home">
        <SEO
          title="Welcome!"
          description="We are a public bar and event hall! Come down for our weekly events and/or book us for your next wedding / event / fundraiser!"
        />

        <Navbar />

        <div
            className="fb-customerchat"
            attribution="setup_tool"
            page_id="KnightsOfColumbus4239"
            // theme_color="..."
            // logged_in_greeting="..."
            // logged_out_greeting="..."
            // greeting_dialog_display="..."
            // greeting_dialog_delay="..."
            // minimized="false"
            // ref="..."
          />

        <section className="home__hero-section">
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
                <div className="left-column">
                  <Typography variant="h2">KC HALL</Typography>
                  <Typography variant="h5">
                    An event hall and public bar located in
                    <br />
                    <strong>O’Fallon, Illinois</strong>.
                  </Typography>
                  <div className="button-row">
                    <Link to={PAGE_URLS.CONTACT}>
                      <Button
                        variant="contained"
                        className={classes.outlinedButton}
                      >
                        CONTACT US
                      </Button>
                    </Link>

                    <Button
                      variant="contained"
                      className={classes.outlinedButton}
                      style={{ marginLeft: 10 }}
                      onClick={scrollToWeeklyEvents}
                    >
                      SEE WEEKLY EVENTS
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="right-column">
                  <GatsbyShadowImage
                    title="KC Hall"
                    altText="Image of KC Hall"
                    gatsbyImage={kchalldark}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="home__main-content-section">
          <Container maxWidth="lg">
            <div className="who-we-are-block">
              <div className="text-block" style={{ paddingBottom: 20 }}>
                <Typography variant="h4" className="lined-title">
                  Who Are We?
                </Typography>
                <Typography variant="body1" paragraph className="mt-2 m-auto">
                  We are a for-profit organization, public bar, and event hall!
                  Use us for your next fundraiser / event!
                </Typography>

                <Grid
                  container
                  spacing={4}
                  className="who-we-are-block__gallery-preview"
                >
                  <Grid item xs={12} sm={6} md={4} className="desktop-only">
                      <GatsbyShadowImage
                        title="Wedding Image"
                        altText="Wedding; Image of Barrel"
                        gatsbyImage={barell}
                      />

                      <GatsbyShadowImage
                        title="Lower Bar Lights"
                        altText="Lower Bar with Lights On"
                        gatsbyImage={downstairsdark}
                      ></GatsbyShadowImage>

                  </Grid>

                  <Grid item xs={12} md={4} className="tablet-only">
                    <GatsbyShadowImage
                      title="Wedding Table Display"
                      altText="Wedding Table Display"
                      gatsbyImage={weddingsetupfar}
                    ></GatsbyShadowImage>
                    <GatsbyShadowImage
                      title="Lower Bar Bright"
                      altText="Lower Bar Bright"
                      gatsbyImage={downstairslight}
                    ></GatsbyShadowImage>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} className="desktop-only">
                    <GatsbyShadowImage
                      title="Wedding Table Display 2"
                      altText="Wedding Table Display 2"
                      gatsbyImage={weddingsetupclose}
                    ></GatsbyShadowImage>
                    <GatsbyShadowImage
                      title="Drinks"
                      altText="Drinks"
                      gatsbyImage={drinks}
                    ></GatsbyShadowImage>
                  </Grid>
                  
                </Grid>
              </div>
            </div>
          </Container>

          <div className="about-us-block text-center">
            <Container maxWidth="lg">
              <Typography variant="h4" className="lined-title">
                What Are We About?
              </Typography>
              <Typography variant="body1" paragraph className="mt-2 m-auto">
                We are here to host your wedding receptions, fundraisers, and
                any other events! Come grab a drink at our bar!
              </Typography>
              <Link to={PAGE_URLS.ABOUT_US}>
                <Button
                  style={{ marginTop: 30 }}
                  className={classes.primaryButton}
                >
                  LEARN MORE
                </Button>
              </Link>
            </Container>
          </div>

          <div
            className="weekly-events-block"
            style={{ marginBottom: 120 }}
            id="weekly-events"
          >
            <Container maxWidth="lg">
              <Grid container spacing={5}>
                <Grid item md={6} xs={12}>
                  <Typography variant="h4" className="lined-title">
                    Weekly Events
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="mt-2 m-auto weekly-events-block__body"
                  >
                    We hold a variety of events weekly; come on down and join
                    the fun!
                  </Typography>

                  <ul className="weekly-events-block__list">
                    <li>
                      <strong>Queen of Hearts</strong> Drawing every Tuesday at
                      7:00 pm.
                    </li>
                    <li>
                      We hold <strong>KC raffle</strong> held in the second and
                      fourth Wednesday of the month.
                    </li>
                    <li>
                      Check back for our <strong>Concert Series</strong>! In the
                      past we have had tribute artists for Elvis, Buddy - Holly,
                      Patsy Cline, the Rat Pack, Elton John, The Eagles, Bob
                      Seeger, Garth Brooks, and many more!
                    </li>
                  </ul>

                  <Link to={PAGE_URLS.ACTIVITIES}>
                    <Button
                      variant="contained"
                      className={classes.primaryButton}
                      style={{ marginTop: 20 }}
                    >
                      SEE WEEKLY EVENTS
                    </Button>
                  </Link>
                </Grid>
                <Grid item md={6} xs={12} className="calendar-column">
                  <Calandar />
                </Grid>
              </Grid>
            </Container>
          </div>

          <div className="what-we-offer-block">
            <Container maxWidth="lg">
              <div className="text-block">
                <Typography variant="h4" className="lined-title white">
                  What We Offer
                </Typography>
                <Typography variant="body1" paragraph className="mt-2 m-auto">
                  We Offer a variety of services! Feel free to{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={scrollToContactForm}
                  >
                    call us or to shoot us an email
                  </span>{" "}
                  with our request!
                </Typography>
              </div>

              <Swiper>
                {services.map(service => (
                  <Card
                    title={service.title}
                    imageUrl={service.imageUrl}
                    key={service.imageUrl}
                    link={service.link}
                    buttonText="Learn More"
                  >
                    {service.body}
                  </Card>
                ))}
              </Swiper>

              <Link to={PAGE_URLS.SERVICES}>
                <Button
                  style={{
                    marginTop: 30,
                    width: "100%",
                    maxWidth: 250,
                    marginBottom: 20,
                  }}
                  className={classes.outlinedButton}
                >
                  LEARN MORE
                </Button>
              </Link>
            </Container>
          </div>

          <div className="need-another-need-block">
            <Typography variant="h4" className="lined-title">
              Need Another Use For Our Space?
            </Typography>
            <Typography variant="body1" paragraph className="mt-2 m-auto">
              We have the space! Whatever event, party, or fundraiser you are
              throwing, we have the space and the facilities you need!
            </Typography>
            <Button style={{ marginTop: 30 }} className={classes.primaryButton}>
              LEARN MORE
            </Button>
          </div>
        </section>

        <div id="contact">
          <ContactUsSection />
        </div>
      </div>
    </Layout>
  )
}

export default HomePage2

export const query = graphql`
  query {

    kchalldark: file(relativePath: { eq: "kchalldark.png" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }

    kchalloutside: file(relativePath: { eq: "kchalloutside3.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }

    kchalloutside2: file(relativePath: { eq: "kchalloutside2.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }

    kchallplates: file(relativePath: { eq: "kchallplates.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }
  

    barell: file(relativePath: { eq: "barell.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }

    downstairsdark: file(relativePath: { eq: "downstairsdark.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }

    downstairslight: file(relativePath: { eq: "downstairslight.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }

    drinks: file(relativePath: { eq: "drinks.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }

    weddingsetupclose: file(relativePath: { eq: "weddingsetupclose.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }

    weddingsetupfar: file(relativePath: { eq: "weddingsetupfar.jpg" }) {
      extension
      dir
      modifiedTime
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 600, quality: 80) {
          src
        }
      }
    }
  }
`
