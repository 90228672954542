import React, { useState, useEffect } from "react"
import Calendar from "react-calendar"
import moment from "moment"
import { EventsService } from "../services/events.service"

const AppEventDot = props => {
  const { date, events } = props

  const hasEventOnThisDay = events.find(x =>
    moment(date).isSame(moment(x.startTime), "d")
  )

  return hasEventOnThisDay ? (
    <span className="app-calendar__indicator"></span>
  ) : null
}

const AppCalendar = () => {

  const [date, setDate] = useState(null)
  const [events, setEvents] = useState([])
  const [todayEvents, setTodayEvents] = useState([])

  const handleDateChange = e => {
    setDate(e)

    if (moment(e).month() !== moment().month) {
      handleMonthChange(e)
    }

  }

  const handleMonthChange = async e => {
    const fetchedEvents = await EventsService.getWeeklyEvents(moment(e))
    setEvents(fetchedEvents)
  }

  async function loadEvents(dateMoment) {
    const fetchedEvents = await EventsService.getWeeklyEvents(dateMoment)
    setEvents(fetchedEvents)
  }

  function getEventsForSelectedDate(dateMoment) {
    const foundTodayEvents = events.filter(x => dateMoment.isSame(x.startTime, "d"))
    setTodayEvents(foundTodayEvents)
  }

  useEffect(() => {
    loadEvents(moment())
    setDate(new Date());
  }, [])

  useEffect(() => {
    getEventsForSelectedDate(moment(date))
  }, [date, events])

  return (
    <div className="app-calendar">
      <Calendar
        calendarType="US"
        onChange={handleDateChange}
        value={date}
        style={{ margin: "0 auto" }}
        onActiveDateChange={e => handleMonthChange(e.activeStartDate)}
        tileContent={props => <AppEventDot {...props} events={events} />}
      />

      {[0, 1].map((i) => {
        const event = todayEvents[i]
        const { name, description, startTime, endTime } = event || {}
        const isOpen = event ? "open" : ""

        const formattedStartTime = startTime && moment(startTime).format("LT")
        const formattedEndTime = endTime && moment(endTime).format("LT")
        return (
          <div className={`app-calendar__event-container ${isOpen}`} key={i}>
            <div className="app-calendar__event-container--name">{name}</div>
            <div className="app-calendar__event-container--description">
              {description}
            </div>

            <div className="app-calendar__event-container--time-row">
              <div>{formattedStartTime}</div>
              <div>{formattedEndTime || '—'}</div>
            </div>
          </div>
        )
      })}

    </div>
  )
}

export default AppCalendar
