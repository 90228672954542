import moment from "moment"

import { EventModel } from "./event.model"
import { EventTimeModel } from "./eventTime.model"

export class EventsService {
  static getCalendarEvents() {}

  static async getWeeklyEvents(dateMoment) {
    return [
      ...this.getKCRaffleDatesForTheMonth(moment(dateMoment)),
      ...this.getQueenOfHeartsForTheMonth(moment(dateMoment)),
      ...this.getXtremeBingoForTheMonth(moment(dateMoment)),
      // ...this.getBingoDatesForTheMonth(moment(dateMoment)),
      ...this.getFishFryDatesForTheMonth(moment(dateMoment)),
    ]
  }

  // 2nd and 4th Wednesday
  static getKCRaffleDatesForTheMonth(dateMoment) {
    var startOfMonth = dateMoment.set({ date: "1" })
    var chosenDay = startOfMonth.day("Wednesday")
    const dates = []

    if (chosenDay.date() > 7) chosenDay.add(7, "d")

    var month = chosenDay.month()
    var index = 1
    while (month === chosenDay.month()) {
      var startTime = moment(chosenDay).set({
        hours: 6 + 12,
        minutes: 30,
      })
      var endTime = null

      if (index % 2 === 0) {
        dates.push({ startTime, endTime })
      }

      chosenDay.add(7, "d")

      index += 1
    }

    return [...dates].map(
      x =>
        new EventModel(
          "KC Raffle",
          "Raffle Starts Today at 6:30pm!",
          x.startTime,
          x.endTime
        )
    )
  }

  static getBingoDatesForTheMonth(dateMoment) {
    const dates = this.getWeeklyEventsForDay(
      dateMoment,
      "Sunday",
      new EventTimeModel(6, 15)
    )

    return [...dates].map(
      x =>
        new EventModel(
          "Bingo",
          "Early Bird starts  at 6:15 pm!",
          x.startTime,
          x.endTime
        )
    )
  }

  static getQueenOfHeartsForTheMonth(dateMoment) {
    const dates = this.getWeeklyEventsForDay(
      dateMoment,
      "Tuesday",
      new EventTimeModel(7, 0)
    )
    return [...dates].map(
      x =>
        new EventModel(
          "Queen of Hearts",
          "Drawing starts at 7:00 pm Today!",
          x.startTime,
          x.endTime
        )
    )
  }

  static getXtremeBingoForTheMonth(dateMoment) {
    const dates = this.getWeeklyEventsForDay(
      dateMoment,
      "Tuesday",
      new EventTimeModel(7, 30)
    )
    return [...dates].map(
      x =>
        new EventModel(
          "Xtreme Bar Bingo",
          "Xtreme Bar Bingo follows the Queen of Hearts drawing!",
          x.startTime,
          x.endTime
        )
    )
  }

  static getFishFryDatesForTheMonth(dateMoment) {
    const dates = this.getWeeklyEventsForDay(
      dateMoment,
      "Friday",
      new EventTimeModel(4, 0),
      new EventTimeModel(7, 0)
    )
    return [...dates].map(
      x =>
        new EventModel(
          "Fish Fry",
          "Come Get It While It's Hot!",
          x.startTime,
          x.endTime
        )
    )
  }

  static getWeeklyEventsForDay(dateMoment, dayString, startDate, endDate) {
    var startOfMonth = dateMoment.set({ date: "1" })
    var chosenDay = startOfMonth.day(dayString)
    const dates = []

    if (chosenDay.date() > 7) chosenDay.add(7, "d")

    var month = chosenDay.month()
    while (month === chosenDay.month()) {
      var startTime = moment(chosenDay).set({
        hours: startDate.hour,
        minutes: startDate.minute,
      })
      var endTime = endDate
        ? moment(chosenDay).set({
            hours: endDate.hour,
            minutes: endDate.minute,
          })
        : null

      dates.push({ startTime, endTime })
      chosenDay.add(7, "d")
    }

    return dates
  }
}
