import React from 'react'
import {fb} from "../services/facebookChat.service"
import {config} from "../../config"

export const ConfigContext = React.createContext({});

export function useConfig() {
  return React.useContext(ConfigContext);
}

class CustomerChat extends React.PureComponent {
    componentDidMount() {
    //   this.timeout = setTimeout(() => {
    //     fb(FB => this.timeout && FB.XFBML.parse());
    //   }, 2000);
        fb(FB => FB.CustomerChat.show(true))
    }
  
    componentWillUnmount() {
      clearTimeout(this.timeout);
      delete this.timeout;
    }
  
    render() {
      return (
        // <ConfigContext.Consumer>
        //   {x => (
            <div
              className="fb-customerchat"
              attribution="setup_tool"
              page_id={config.facebookChatId}
              // theme_color="..."
              // logged_in_greeting="..."
              // logged_out_greeting="..."
              // greeting_dialog_display="..."
              // greeting_dialog_delay="..."
              // minimized="false"
              // ref="..."
            />
        //   )}
        // </ConfigContext.Consumer>
      );
    }
  }
  
  export default CustomerChat;