import { useEffect } from "react"
import platform from "platform"

// Notes: this detects what browser the user is currently on and adds to body
export const detectBrowser = () => {
  const browser = platform.name
  const body = document.querySelector("body")
  var bodyClass = ""

  switch (browser) {
    case "Chrome":
      bodyClass = "isChrome"
      break
    case "Firefox":
      bodyClass = "isFirefox"
      break
    case "Safari":
      bodyClass = "isSafari"
      break
    case "Microsoft Edge":
      bodyClass = "isEdge"
      break
    case "IE":
      bodyClass = "isIE"
      break
    default:
      break
  }

  body.className += ` ${bodyClass}`

  console.log("Browser:", browser)
}
